<template>
  <div>
    <!-- 头部导航 -->
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>
    <!-- 中间内容 -->
    <div class="container py-2">
      <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="course">
        <template #label>
          <span>
            <svg-icon icon-class="baokaozhuanye"></svg-icon>
            套餐课程
          </span>
        </template>
        <div class="course-container" v-if="courses.length > 0">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(course,index) in courses" :key="index">
              <div class="course-card">
                <el-card>
                  <img :src="course.thumb" class="image">
                  <div class="content" @click.stop="toCourseDetail(course)">
                    <span class="title">{{course.title}}</span>
                    <div class="bottom">
                      <time class="price">¥{{ course.sale_price }}</time>
                      <el-button :type="$config.buttonType" @click.stop="toCourseDetail(course)">查看详情</el-button>
                    </div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-empty description="没有查询到相关套餐课程信息"></el-empty>
        </div>
      </el-tab-pane>
      <el-tab-pane name="exam">
        <template #label>
          <span>
            <svg-icon icon-class="kaoshi"></svg-icon>
            在线题库
          </span>
        </template>
        <div class="exam-container" v-if="exams.length > 0">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(exam,index) in exams" :key="index">
              <div class="exam-card" @click.stop="toExamDetail(exam)">
                <el-card>
                  <div class="image">
                    <img :src="exam.thumb" alt="">
                  </div>
                  <div class="content">
                    <span class="title">{{exam.name}}</span>
                    <div class="bottom">
                      <time class="price">¥{{ exam.sale_price }}</time>
                      <el-button :type="$config.buttonType" @click.stop="toExamDetail(exam)">查看详情</el-button>
                    </div>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-empty description="没有查询到相关题库信息"></el-empty>
        </div>
      </el-tab-pane>
      <el-tab-pane name="news">
        <template #label>
          <span>
            <svg-icon icon-class="zixun"></svg-icon>
            考试资讯
          </span>
        </template>
        <div class="news-container" v-if="news.length > 0">
          <div class="news-card" v-for="(item,index) in news" :key="index">
            <div class="image">
              <img :src="item.article_thumb">
            </div>
            <div class="content">
              <div class="title">
                <a :href="'/newsdetail/' + item.article_id ">{{item.article_title}}</a>
              </div>
              <div class="tags">
                <el-tag :type="$config.buttonType">{{item.create_time}}</el-tag>
              </div>
              <div class="tags" v-if="item.article_author">
                <el-tag :type="$config.buttonType">item.article_author</el-tag>
              </div>
              <div class="description">
                {{item.article_intro}}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <el-empty description="没有查询到相关资讯信息"></el-empty>
        </div>
      </el-tab-pane>
      <el-tab-pane name="teacher">
        <template #label>
          <span>
            <svg-icon icon-class="jiaoshi"></svg-icon>
            行业导师
          </span>
        </template>
        <div class="teacher-container" v-if="teachers.length > 0">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(teacher,index) in teachers" :key="index">
              <div class="teacher-card" @click.stop="toTeacherDetail(teacher)">
                <el-card>
                  <div class="top">
                    <div class="image">
                      <img :src="teacher.thumb">
                    </div>
                    <div class="content">
                      <span class="title">{{teacher.name}}</span>
                      <br>
                      <div class="bottom">
                        <el-button :type="$config.buttonType" size="mini" @click.stop="toTeacherDetail(teacher)">查看详情</el-button>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    {{stripHTML(teacher.content)}}
                  </div> 
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-empty description="没有查询到行业导师信息"></el-empty>
        </div>
      </el-tab-pane>
    </el-tabs>
    </div>
    <!-- footer -->
    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from '@/components/page/NavBar'
import ToolBox from '@/components/toolbox'
import WebFooter from '@/components/page/WebFooter'
import {searchData} from '@/api/search'
import {stripHTML} from '@/utils/index'
import {setSessionStorage} from '@/utils/auth'
export default {
  data() {
    return {
      activeName: 'course',
      courses:[],
      exams:[],
      news:[],
      teachers:[],
      keyword:'',
      currentSearchType:'course',
    }
  },
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  props:{

  },
  created(){
    this.keyword = this.$route.params.keyword;
    this.handleSearch()
  },
  watch:{
    '$route':function(){
      this.keyword = this.$route.params.keyword;
      this.currentSearchType = 'course'
      this.activeName = 'course'
      this.handleSearch()
    }
  },
  methods:{
    stripHTML,
    handleClick(tab, event) {
      if(tab.props.name != this.currentSearchType){
        this.currentSearchType = tab.props.name
        this.handleSearch()
      }
    },
    handleSearch(){
      //将关键字从路由参数中获得
      this.keyword = this.$route.params.keyword;
      //发起请求
      switch(this.currentSearchType){
        case 'course':
          searchData({
            type:1,
            title:this.keyword,
            page:1
          }).then(
            res=>{
              if(res.code == 1){
                this.courses = res.data
                console.log(this.courses)
              }
            }
          )
          break;
        case 'exam':
          searchData({
            type:3,
            title:this.keyword,
            page:1
          }).then(
            res=>{
              if(res.code == 1){
                this.exams = res.data
              }
            }
          )
          break;
        case 'news':
          searchData({
            type:4,
            title:this.keyword,
            page:1
          }).then(
            res=>{
              if(res.code == 1){
                this.news = res.data.data
              }
            }
          )
          break;
        case 'teacher':
          searchData({
            type:5,
            title:this.keyword,
            page:1
          }).then(
            res=>{
              if(res.code == 1){
                this.teachers = res.data.data
              }
            }
          )
          break;
      }
    },
    toCourseDetail(data){
      this.$router.push('/coursedetail/' + data.spec_id + '/' + data.comboclass_id + '/' + data.id );
    },
    toExamDetail(data){
      setSessionStorage("currentTestGood", data)
      this.$router.push('/secretdetail')
    },
    // toNewsDetail(data){

    // },  
    toTeacherDetail(data){
      this.$router.push('/teacherdetail/' + data.id)
    }
  }
}
</script>

<style scoped lang="scss">
.course-container{
  padding:0px 10px 10px 10px;
  .course-card{
    transition-duration: 0.5s;
    transition-property: box-shadow;
    &:hover{
      box-shadow:0px 0px 3px 3px #ccc;
    }
    margin-top:20px;
    .content{
      padding:14px;
      .title{
        display:inline-block;
        font-size:16px;
        font-weight:400;
        height:50px;
        overflow: hidden;
      }
      .price {
        font-size: 16px;
        color: $price-color;
      }
      .bottom {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .image {
      width: 100%;
      display: block;
    }
  }
}

.exam-container{
  padding:0px 10px 10px 10px;
  .exam-card{
    transition-duration: 0.5s;
    transition-property: box-shadow;
    &:hover{
      box-shadow:0px 0px 3px 3px #ccc;
    }
    margin-top:20px;
    .image{
      width:100%;
      img{
        width:100%;
      }
    }
    .content{
      padding:14px;
      .title{
        display:inline-block;
        font-size:16px;
        font-weight:400;
        height:60px;
        overflow: hidden;
      }
      .price {
        font-size: 16px;
        color: $price-color;
      }
      .bottom {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .image {
      width: 100%;
      display: block;
    }
  }
}
.news-container{
  padding:0px 10px 10px 10px;
  display:flex;
  flex-direction: column;
  .news-card{
    padding-bottom:10px;
    border-bottom:dashed 1px $theme-color;
    margin-top:10px;
    display: flex;
    flex-direction: row;
    .image{
      width:200px;
      img{
        width:100%;
      }
    }
    .content{
      width:1100px;
      padding:0px 10px;
      .title{
        font-size:16px;
        a{
          color:#333;
          &:hover{
            color:$theme-color;
          }
        }
      }
      .tags{
        height:40px;
        line-height:40px;
      }
      .description{
        font-size:16px;
        color:#666;
      }
    }
  }
}
.teacher-container{
  padding:0px 10px 10px 10px;
  .teacher-card{
    display: flex;
    flex-direction:column;
    height:310px;
    transition-duration: 0.5s;
    transition-property: box-shadow;
    &:hover{
      box-shadow:0px 0px 3px 3px #ccc;
    }
    margin-top:20px;
    .top{
      height:200px;
      padding:14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .image {
        width:150px;
        height:200px;
        overflow: hidden;
        img{
          width:100%;
        }
      }
      .content{
        width:100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .title{
          font-size:16px;
          font-weight:400;
        }
      }
    }
    .bottom {
      padding:0px 10px 10px 10px;
      height:60px;
      margin-bottom:10px;
      overflow: hidden;
      line-height: 22px;
    }
  }
}

</style>
<style lang="scss">
//el-tabs
.container .el-tabs__nav-wrap::after{
  height:1px;
}
.container .el-tabs__item{
  font-size:16px;
  // font-weight: bold;
  color:#333;
}
.container .el-tabs__item:hover{
  color:$theme-color;
}
.container .el-tabs__item.is-active{
  color:$theme-color;
}
.container .el-tabs__active-bar{
  background-color:$theme-color;
}
// el-card
.course-card .el-card{
  border-radius:0px;
}
</style>