import request from '@/utils/request'
import qs from 'qs';

/**
 * 查询网站信息
 * @returns 
 */
export function searchData(data) {
  return request({
    url: '/index/search',
    method: 'post',
    data: qs.stringify(data)
  })
}